@import '/styles/base.scss';

.sidebar {
  position: fixed;
  z-index: $z-200;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100 * var(--vh));
  pointer-events: none;

  &.active {
    pointer-events: all;
  }
}

.inner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: rgba($color-black, 0.7);
  cursor: initial;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  .sidebar.active & {
    opacity: 1;
    pointer-events: all;
  }
}

.wrapper {
  position: relative;
  display: flex;
  width: 100vw;
  height: calc(100 * var(--vh));
  flex-direction: column;
  background: $color-white;
  transform: translateX(100%);
  transition: transform 0.4s $ease-in-out-cubic;

  @include md {
    width: 50vw;
  }

  @include lg {
    width: calc(80vw / 9 * 4);
  }

  .sidebar.active & {
    transform: translateX(0);
    transition: transform 0.8s $ease-out-expo;
  }
}

.header {
  padding: 7.2rem 3.2rem 2.4rem;
  border-bottom: 1px solid $color-gray-light;

  @include lg {
    padding: 5.2rem 4.8rem 2.4rem;
  }
}

.content {
  flex: 1;
  padding: 3.2rem 3.2rem 4.8rem;
  overflow-y: auto;

  @include lg {
    padding: 4.8rem;
  }
}

.actions {
  display: flex;
  padding: 2.4rem 3.2rem;
  border-top: 1px solid $color-gray-light;
  gap: 1.6rem;

  @include lg {
    padding: 2.4rem 4.8rem 4rem;
  }

  button {
    flex: 1;
  }
}

.actionPrimary {
  white-space: nowrap;
}

.cancel {
  position: absolute;
  top: 3.6rem;
  right: 3.6rem;
  color: $color-black;
  transition: color 0.3s ease;

  &:hover {
    color: rgba($color-black, 0.65);
  }
}

.cancelIcon {
  width: 2.4rem;
}
