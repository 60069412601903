@import '/styles//base.scss';

.checkbox {
  & + & {
    margin-top: 2.6rem;
  }

  & + * {
    margin-top: 3.2rem;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.input {
  opacity: 0;

  &:focus-visible + .label {
    @include field-focus;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.mark {
  position: relative;
  display: flex;
  width: 2.2rem;
  height: 2.2rem;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-black;
  border-color: lighten($color-black, 70%);
  border-radius: 2px;
  margin-right: 1.6rem;
  color: transparent;
  transition:
    background-color 0.2s ease,
    border-color 0.3s ease,
    color 0.2s ease;

  .label:hover & {
    border-color: $color-black;
  }

  .label.active & {
    border-color: $color-black;
    background-color: $color-black;
    color: $color-white;
  }
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
}
