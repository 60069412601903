@import '/styles/base.scss';

.fieldset {
  padding: 0;
  border: none;
  margin: 0;
}

.radio {
  position: relative;
  display: flex;

  & + & {
    margin-top: 2.6rem;
  }
}

.mark {
  position: relative;
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border: 2px solid $color-black;
  border-radius: 100px;
  margin-right: 1.6rem;
  opacity: 0.3;
  transition:
    opacity 0.2s ease,
    box-shadow 0.2s ease;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    box-shadow: inset 0 0 0 0 black;
    content: '';
  }

  .label:hover & {
    opacity: 1;
  }
}

.input {
  position: absolute;
  left: -9999px;
  opacity: 0;

  &:focus-visible ~ .label {
    @include field-focus;
  }

  &:checked + .label > .mark {
    opacity: 1;

    &::after {
      box-shadow: inset 0 0 0 5px black;
    }
  }
}

.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
