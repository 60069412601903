@import '/styles/base.scss';

.title {
  margin-bottom: 4.8rem;

  @include lg {
    margin-bottom: 6.2rem;
  }
}

.button {
  margin-top: 4.8rem;

  @include lg {
    margin-top: 6.2rem;
  }
}
