@import '/styles/base.scss';

.item {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 3.2rem;

    @include lg {
      margin-top: 4rem;
    }
  }
}

.action {
  @include focus;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  color: $color-black;
  cursor: pointer;
  text-align: left;

  > div {
    width: 100%;
    margin-right: 0;
  }
}

.iconWrapper {
  text-align: right;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-top: 0.4rem;
}

.expand {
  transition: padding 0.2s ease;
  transition-delay: 0.2s;

  &.isExpanded {
    padding-top: 3.2rem;
    transition-delay: 0s;
  }
}
