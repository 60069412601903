@import '/styles/base.scss';

.login {
  position: absolute;
  top: 2.4rem;
  right: 2.1rem;
  display: flex;
  padding: 0;

  @include md {
    top: 4rem;
    right: 4.8rem;
  }

  @include lg {
    top: 10.5rem;
    right: 4.5rem;
  }
}

.callToAction {
  @include sm-only {
    margin-bottom: 6.4rem;
  }
}
