@import '/styles/base.scss';

.header {
  margin-bottom: 2.4rem;
  @include modulesTitle;
}

.description {
  margin-bottom: 3.2rem;
}

.links {
  > * + * {
    margin-top: 2.4rem;
  }
}

.linksExpandable {
  > * + * {
    margin-top: 3.2rem;
    @include lg {
      margin-top: 4rem;
    }
  }
}

.button {
  margin-top: 3.2rem;

  @include lg {
    margin-top: 4rem;
  }
}
