@import '/styles/base.scss';

.quickLinks {
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2.4rem 4rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    var(--theme-primary);

  @include lg {
    padding: 4rem;
  }
}

.eyebrow {
  margin-bottom: 0.4rem;
}

.title {
  margin-bottom: 3.2rem;

  @include lg {
    margin-bottom: 4rem;
  }
}

.links {
  display: flex;
  flex-direction: column;
  // 2.1rem + 6px gap between lines (3px on each side) is equal to 2.4rem gap
  gap: 2.1rem;
}

.link {
  // 6px gap between lines
  line-height: calc(1em + 6px);
}
