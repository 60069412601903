.title {
  margin-bottom: 1.4rem;
}

.text {
  margin-bottom: 1.4rem;
}

.address {
  margin-top: 2.4rem;
}
