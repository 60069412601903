@import '/styles/base.scss';

.email {
  position: relative;
}

.emailButton {
  @include focus;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 0.8rem 0;
  color: $color-black;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover,
  &.active {
    opacity: 0.65;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh));
}

.emailIcon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
}

.tooltip {
  position: absolute;
  top: -4.5rem;
  left: 0;
  display: flex;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  padding: 1.2rem 1.9rem;
  border-radius: 4.4rem;
  background-color: $color-black;
  color: $color-white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  visibility: hidden;

  &::after {
    position: absolute;
    z-index: -1;
    top: 100%;
    left: 2.6rem;
    width: 0;
    height: 0;
    border-top: 0.9rem solid $color-black;
    border-right: 0.6rem solid transparent;
    border-left: 0.6rem solid transparent;
    content: '';
  }
}

.tooltipVisible {
  min-width: 17.9rem;
  padding: 1.5rem 0;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
