@import '/styles/base.scss';

.card {
  @include focus(-0.4rem);
  display: flex;
  max-width: 19.2rem;
  min-height: 36.8rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.4rem 2.4rem 4rem;
  color: var(--theme-copy);

  @include md {
    min-height: 40.3rem;
  }

  @include lg {
    max-width: 17.6rem;
    min-height: 47.4rem;
    padding: 4rem 4rem 4.8rem;
  }

  &.isThemed {
    background-color: var(--theme-primary);
  }

  &:hover {
    color: var(--theme-copy);

    .link {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }
}

.label {
  margin-bottom: 2.4rem;
}

.link {
  display: flex;
  height: 2.8rem;
  align-items: center;
  color: var(--theme-copy);
  transition: color 0.3s ease;
}
