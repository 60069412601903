@import '/styles/base.scss';

.section {
  & + .section {
    margin-top: 4.8rem;

    @include lg {
      margin-top: 6.4rem;
    }
  }
}

.title {
  margin-bottom: 3.2rem;
}
